// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

//= require jquery
//= require jquery_ujs
//= require activestorage

import 'bootstrap'

//= require bootstrap-sprockets
//= require bootstrap-table/bootstrap-table
//= require bootstrap-datetimepicker

window.jQuery = $;
window.$ = $;

// Wait for the view to be rendered by rails and processed by the browser
// then call general setup mechanisms that should run on every page.
$(document).on('turbolinks:load', function () {
  // tooltips init
  $('[data-toggle="tooltip"]').tooltip({
    html: true,
    animation: true
  });

  // indicate chosen file
  $('.form-control-file').each(function () {
    var $input = $(this),
      $label = $input.next('label'),
      labelVal = $label.html();

    $input.on('change', function (e) {
      var fileName = '';

      if (this.files && this.files.length > 1)
        fileName = this.files.first.split('\\').pop();
      else if (e.target.value)
        fileName = e.target.value.split('\\').pop();

      if (fileName)
        $label.html(`${labelVal}<div class="file-label">ausgewählt: ${fileName}</div>`);
      else
        $label.html(labelVal);
    });

    // Firefox bug fix
    $input
      .on('focus', function () { $input.addClass('has-focus'); })
      .on('blur', function () { $input.removeClass('has-focus'); });
  });
});
